<template>
<div v-if="'no' in product">
  <div class="container main-container">
    <div class="row">
      <div class="col-md-11 col-12">
        <div class="product-box row">
          <div class="col-12 col-md-5">
            <div class="product-pic">
              <img v-lazy="photoUrl" alt="">
            </div>
          </div>
          <div class="col-12 col-md-7">
            <div class="product-info">
              <div class="product-main">
                <!-- <span v-show="item.winery.data[0].name!=0" class="winery">{{item.winery.data[0].name}}</span> -->
                <h1 class="main-title">{{ product.name }}</h1>
                <h2 class="sub-title">{{ product.name_en }}</h2>
                <div class="wine-tag-group">
                  <ul class="d-flex flex-wrap">
                    <li v-for="(tag, index) in product.tags.data" :key="index" class="tag-item">
                      <router-link :to="{path: '/search/wine', query:{ tag: tag.name, tag_id: tag.id }}">{{ tag.name }}</router-link>
                    </li>
                  </ul>
                </div>
                <div class="ask-btn-list" v-if="product.icheers_url">
                    <a :href="product.icheers_url" target="_blank">
                      <div class="purple-btn">
                        <span>立即詢價</span>
                        <i class="icon-ask"></i>
                      </div>
                    </a>
                  </div>
                <div class="price-btn-list">
                  <div class="all-price">
                    <div class="suggested">建議售價
                      <span>{{ price }}</span>
                    </div>
                    <div class="market">市場行情
                      <span>{{ marketPrice }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="product-sub">
                  <table>
                      <tbody>
                          <tr v-for="value, spec in product.spec">
                              <td>{{ spec }}</td>
                              <td>{{ value }}</td>
                          </tr>
                      </tbody>
                  </table>
              </div>
            </div>
          </div>
          <div class="wine-others-intro">
              <ul class="nav">
                  <li class="nav-item" v-if="product.features" @click="selected(1)">
                    <a :class="{'nav-link':true, active: tabIndex == 1}">酒品描述</a>
                  </li>
                  <li class="nav-item" v-if="product.winery?.data[0]?.content" @click="selected(2)">
                    <a :class="{'nav-link':true, active: tabIndex == 2}">品牌故事</a>
                  </li>
                  <li class="nav-item" v-if="comments.length" @click="selected(3)">
                    <a :class="{'nav-link':true, active: tabIndex == 3}">評語</a>
                  </li>
                  <li class="nav-item" v-if="awards.length" @click="selected(4)">
                    <a :class="{'nav-link':true, active: tabIndex == 4}">國際獎項</a>
                  </li>
              </ul>
              <div class="intro">
                <div v-if="product.features" :class="{'intro-tab':true, active: tabIndex == 1}">
                  <p v-html="product.features"></p>
                </div>
                <div v-if="product.winery?.data[0]?.content" :class="{'intro-tab':true, active: tabIndex == 2}">
                  <p v-html="product.winery?.data[0]?.content"></p>
                </div>
                <div v-if="comments.length" :class="{'intro-tab':true, active: tabIndex == 3}">
                  <template v-for="(comment, index) in comments" :key="index">
                    <p>
                      <span v-html="comment.content"></span> - 
                      <span>{{ comment.author.data[0].name }}</span>_
                      <span>{{ comment.year }}年</span>_
                      <span>{{ Math.round(comment.score) }}分</span>
                    </p>
                  </template>
                </div>
                <div v-if="awards.length" :class="{'intro-tab':true, active: tabIndex == 4}">
                  <template v-for="(award, index) in awards" :key="index">
                    <div class="product-award p-10">
                      <img v-lazy="award.media.data[0].medium" alt="">
                      <span>{{ award.name }}</span>
                    </div>
                  </template>
                  <p></p>
                </div>

              </div>
          </div>
        </div>
      </div>
      <div class="col-12" v-if="likeProducts.length">
        <h3 class="text-center">你可能也喜歡</h3>
        <CardWine :wines="likeProducts"/>
      </div>
      <div class="col-12" v-if="relateArticles.length">
        <h3 class="text-center">相關文章</h3>
        <div class="d-flex justify-content-left flex-wrap px-15">
          <div class="article-wrapper p-10" v-for="(article, index) in relateArticles" :key="index">
            <ItemArticle class="large" :article="article" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { ProductService, PostService } from "@/apis/service";
import dotProp from 'dot-prop'

function stripHtml(html)
{
   let tmp = document.createElement("DIV");
   tmp.innerHTML = html;
   return tmp.textContent || tmp.innerText || "";
}

export default {
  props: {
    no: String,
  },
  data() {
    return {
      product: {},
      tabIndex: 1,
      comments: [],
      awards: [],
      relateArticles: [],
      likeProducts: [],
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.fetchData(to))
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchData(to)
    next()
  },
  computed: {
    price() { return dotProp.get(this.product,'price').toLocaleString('zh-TW', {style: 'currency',currency: 'TWD', minimumFractionDigits: 0}) },
    marketPrice() { return dotProp.get(this.product,'market_price').toLocaleString('zh-TW', {style: 'currency',currency: 'TWD', minimumFractionDigits: 0}) },
    photoUrl() { return dotProp.get(this.product,'media.data.0.original') },
    socialPhotoUrl() { return dotProp.get(this.product,'social_image.data.0.large') ||
      dotProp.get(this.product,'media.data.0.large')
    },
    authorName() { return dotProp.get(this.product,'.author.data.0.name') },
    publishDate() { return dotProp.get(this.product,'publish_at') },
  },
  methods: {
    async fetchData (to) {
      const productResp = await ProductService.get(to.params.no)
      if (!productResp.data.data[0]) {
        window.location = "/404.html"
      }
      this.product = productResp.data.data[0]
      this.likeProducts = this.product.associate_products.data
      this.relateArticles = this.product.associate_posts.data
      const features = stripHtml(this.product.features).slice(0,40)
      this.$store.commit('setMeta', {
        title: `${this.product.name}｜${this.product.name_en}｜酒訊網`,
        og: {
          url: window.location.toString().replace(location.search, ""),
          type: 'website',
          title: `${this.product.name}｜${this.product.name_en}｜酒訊網`,
          description: `${this.product.name}｜${this.product.name_en}｜${features}，找${this.product.name}就來酒訊網`,
          image: this.socialPhotoUrl,
        },
        meta: [
          {
            property: 'fb:app_id',
            content: 671097574106450,
          },
          {
            name: 'description',
            content: `${this.product.name}｜${this.product.name_en}｜${features}，找${this.product.name}就來酒訊網`,
          },
        ],
        link: [
          {
            href: window.location.toString().replace(location.search, ""),
            rel: "canonical",
          }
        ],
      })
      this.tabIndex = 1
      const tags = this.product.tags.data.map(a => a.name).join(',')
      const commentResp = await ProductService.comments(to.params.no)
      this.comments = commentResp.data.data
      const awardResp = await ProductService.awards(to.params.no)
      this.awards = awardResp.data.data
      this.$store.commit('setBreadcrumb',[{
        name: this.product.name,
        to: '/',
      }])
      this.$store.commit('setLoading', false)

    },
    selected(index) {
      this.tabIndex = index
    },
  }
}
</script>


<script setup>
import CardWine from '@/components/card/Wine.vue'
import ItemArticle from '@/components/item/Article.vue'
</script>

<style lang="scss" scoped>
.purple-btn {
  background-color: #542836;
  border-radius: 10px;
  font-weight: 700;
  display: inline-block;
  white-space: nowrap;
  padding:1px 10px;
  i {
    @include media-breakpoint-down(xs){
      display: none;
    }
  }
  span {
    display: inline-block;
    color: #fff;
    padding: 3px 0px;
  }
}


.product-box {
  background-color: #fff;
  padding: 30px;
  margin-bottom: 50px;
  .product-pic {
    img {
      width: 100%;
    }
  }
  .product-info {
    .product-main {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 3px solid #D80C24;

      .winery {
        border-bottom: 3px solid #B20419;
        display: inline-block;
        margin-bottom: 3px;
      }

      .main-title {
        font-size: 24px;
        line-height: 36px;
      }

      .sub-title {
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
      }

      .wine-tag-group {
        padding-top: 0;

        ul li {
          margin: 5px 10px 5px 0;
        }
      }

      .price-btn-list {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .all-price {
          display: flex;
          flex-wrap: wrap;

          div {
            font-size: 18px;
            font-weight: 700;
            margin-right: 30px;

            span {
              color: #B20419;
              font-size: 24px;
              margin-left: 8px;
            }
          }
        }
      }



        .ask-btn-list {
          margin: 15px 0;
          .purple-btn {
            font-size: 18px;
            line-height: 30px;

            a {
              padding: 5px 15px;
            }
          }
        }

    }

    .product-sub table tr td {
      font-size: 18px;

      &:first-child {
        color: #A6A8AC;
        padding: 8px 0;
        white-space: nowrap;
        font-weight: 700;
      }

      &:last-child {
        padding-left: 50px;
      }
    }
  }

  .wine-others-intro {
    .nav {
      margin-top: 100px;
      border-bottom: 1px solid #A6A8AC;

      .nav-item {
        color: #B20419;
        font-size: 20px;
        font-weight: 700;
        cursor: pointer;
        .nav-link.active {
          border-bottom: 5px solid #0C121C;
        }
      }
    }

    .intro {
      padding: 30px;
      font-size: 18px;
      line-height: 32px;
      .intro-tab {
        display: none;

        &.active {
          display: block;
        }
      }
    }
  }
}




.article-wrapper {
  width: 20%;
  @include media-breakpoint-down(sm){
    width: 50%;
  }
  @include media-breakpoint-down(xs){
    width: 100%;
  }
}

.product-award {
  display: inline-block;
  width: 200px;
  text-align: center;
}
</style>