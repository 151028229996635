<template>
  <div v-if="category">
    <h3 class="category-title d-flex justify-content-between align-items-center">
      <span>{{ category.name }}</span>
      <router-link class="more" :to="{ path: '/article', query: { category: category.id } }">看更多<i
          class="fas fa-caret-right ml-2"></i></router-link>
    </h3>
    <template v-if="splide && [4, 309].includes(category.id)">
      <splide :options="options" class="item-list row no-gutters" :slides="category.posts?.data || []">
        <slide v-for="article in articles" :key="article.no">
          <div class="col-lg-6 col-12 content-wrapper">
            <ItemArticle :article="article" class="large" />
          </div>
        </slide>
      </splide>
    </template>
    <template v-else>

      <div class="d-flex flex-column item-wrapper h-100">
        <ItemArticle class="small" v-for="article in articles" :key="article.no" :article="article" />
      </div>
    </template>
  </div>
</template>

<script setup>
import ItemArticle from '@/components/item/Article.vue'
import { Splide, SplideSlide as slide } from '@splidejs/vue-splide';
import { computed } from 'vue'
import { useStore } from 'vuex'
const props = defineProps({
  categoryId: Number,
  splide: Boolean,
});
const store = useStore()
const category = computed(() => store.getters.getCategoryById(props.categoryId))
const articles = computed(() => {
  const articleCount = props.splide ? count[category.value.id] : 5
  return category.value.posts?.data.slice(0, articleCount) || []
})
const options = {
  type: 'slide',
  arrows: false,
  interval: 5000,
  autoplay: true,
  rewindSpeed: 0,
  rewind: true,
}
const count = {
  4: 3,
  57: 2,
  62: 2,
  68: 2,
  75: 1,
  309: 3
}

</script>

<style lang="scss" scoped>
.category-title {
  font-size: 24px;
  line-height: 42px;
  font-weight: 700;
  color: #B1081A;
  border-bottom: 5px solid #6E7177;
}
</style>
