
<template>
<div :class="direction" v-if="banners">
  <splide :options="options" class="" :slides="banners">
    <slide v-for="banner in banners" :key="banner">
      <div class="ad-cover">
        <a :href="banner.url" target="_blank"><img v-lazy="banner.image" alt=""></a>
      </div>
    </slide>
  </splide>
</div>
</template>

<script setup>
import { defineProps, computed } from "vue";
import { Splide, SplideSlide as slide } from '@splidejs/vue-splide';
const props = defineProps({
  horizontal: Boolean,
  vertical: Boolean,
  image: String,
  banners: Object,
});
const direction = computed(() => {
  if (props.horizontal) {
    return 'horizontal'
  }
  if (props.vertical) {
    return 'vertical'
  }
  return 'horizontal'
})

const options = {
  type: 'slide',
  arrows: false,
  pagination: false,
  interval: 8000,
  autoplay: true,
  speed: 3000,
  rewindSpeed: 0,
  rewind:true,
}
</script>

<style lang="scss" scoped>
.ad-cover {
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
  .horizontal &{
    width: 100%;
    overflow: hidden;
  }
  .vertical &{
    width: 100%;
  }
}

</style>


