<template>
<div class="section">
    <h3 class="section-title"><span>達人專欄</span></h3>
    <div class="container">
    <splide :options="options" class="container slide-expert d-flex flex-column justify-content-center" :slides="experts">
      <slide v-for="expert in experts" :key="expert.name">
        <CardExpert :expert="expert" class="h-100 tiny" :slide="true"/>
      </slide>
    </splide>
    </div>
</div>
</template>

<script setup>
import CardExpert from '@/components/card/Expert.vue'
import { Splide, SplideSlide as slide } from '@splidejs/vue-splide';
import { ref } from "vue";
const props = defineProps({
  title: String,
  experts: {
    type: Array,
    default: [],
  },
});

const options = {
  type: 'loop',
  lazyLoad: 'sequential',
  preloadPages: 5,
  autoWidth: true,
  pagination: false,
}

</script>

<style lang="scss" scoped>
// ::v-deep(.splide__track) {
//   display: flex;
//   justify-content: center;
// }

</style>
